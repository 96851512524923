import React, { useState, useEffect } from "react";

export const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
  const [idOpp, setIdOpp] = useState(null);
  const [queryOpp, setQueryOpp] = useState("");

  return (
    <DataContext.Provider
      value={{
        idOpp,
        setIdOpp,
        queryOpp,
        setQueryOpp,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
