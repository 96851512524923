import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  TableContainer,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import Uf from '../format/Uf';
import EstadoCuota from '../format/EstadoCuota';
import OportunidadFinanciamiento from './OportunidadFinanciamiento';
import { dateToHuman } from '../../core/helpers';

const FinanciamientoCuotas = ({ financiamiento, cuotas, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <OportunidadFinanciamiento financiamiento={financiamiento} onClick={onOpen} {...props} />

      {cuotas && cuotas.length > 0 && (
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cuotas {financiamiento.nombre_tfi}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TableContainer>
                <Table w="100%" variant="simple">
                  <Thead>
                    <Tr>
                      <Th px={0}>N°</Th>
                      <Th px={0}>Estado</Th>
                      <Th px={0}>Vencimiento</Th>
                      <Th px={0}>Monto (U.F.)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {cuotas.map((c) => (
                      <Tr key={c.id_cuo}>
                        <Td px={0}>{ c.numero_cuo }</Td>
                        <Td px={0}><EstadoCuota idEcu={c.id_ecu}>{c.nombre_ecu}</EstadoCuota></Td>
                        <Td px={0}>{dateToHuman(c.fecha_vencimiento_cuo)}</Td>
                        <Td px={0}><Uf>{c.monto_uf_cuo}</Uf></Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Cerrar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

FinanciamientoCuotas.propsTypes = {
  financiamiento: PropTypes.object.isRequired,
  cuotas: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(FinanciamientoCuotas);