import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import Uf from '../format/Uf';
import OportunidadFinanciamiento from './OportunidadFinanciamiento';
import { dateToHuman } from '../../core/helpers';
import IfEmpty from '../format/IfEmpty';

const FinanciamientoSubsidio = ({ financiamiento, subsidio, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <OportunidadFinanciamiento financiamiento={financiamiento} onClick={onOpen} {...props} />

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{financiamiento.nombre_tfi}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table w="100%" variant="simple">
              <Tbody>
                <Tr>
                  <Th px={0}>Monto</Th>
                  <Td px={0} isTruncated><Uf>{financiamiento.monto_uf_fin}</Uf></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Vencimiento</Th>
                  <Td px={0} isTruncated>{dateToHuman(subsidio.fecha_vencimiento_sub)}</Td>
                </Tr>
                <Tr>
                  <Th px={0}>Estado</Th>
                  <Td px={0} isTruncated><IfEmpty>{subsidio.nombre_esu}</IfEmpty></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Región</Th>
                  <Td px={0}><IfEmpty isTruncated>{subsidio.nombre_reg}</IfEmpty></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Programa</Th>
                  <Td px={0}><IfEmpty isTruncated>{subsidio.nombre_psu}</IfEmpty></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Serie</Th>
                  <Td px={0}><IfEmpty isTruncated>{subsidio.serie_sub}</IfEmpty></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Número</Th>
                  <Td px={0}><IfEmpty isTruncated>{subsidio.numero_sub}</IfEmpty></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Llamado</Th>
                  <Td px={0}><IfEmpty isTruncated>{subsidio.nombre_lls}</IfEmpty></Td>
                </Tr>
                <Tr>
                  <Th px={0}>Año</Th>
                  <Td px={0}><IfEmpty isTruncated>{subsidio.anio_sub}</IfEmpty></Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

FinanciamientoSubsidio.propsTypes = {
  financiamiento: PropTypes.object.isRequired,
  subsidio: PropTypes.object.isRequired,
}

export default memo(FinanciamientoSubsidio);