class Config {
  static get appName() {
    return process.env.REACT_APP_NAME;
  }

  static get apiUrl() {
    return process.env.REACT_APP_API_URL;
  }
}

export default Config;
