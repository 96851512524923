import React from 'react';
import {
  Text,
} from '@chakra-ui/react';
import { isUndefined, isNull, isNumber, isBoolean } from 'lodash';

const BoolWord = ({ children, ...props}) => {

  let value = "--";

  if (isUndefined(children) || isNull(children)) {
    value = "No";
  }

  if (isNumber(children)) {
    value = children === 0 ? "No" : "Si";
  }

  if (isBoolean(children)) {
    value = children ? "Si" : "No";
  }

  return <>
    <Text {...props}>
      {value}
    </Text>
  </>;
};

export default BoolWord;