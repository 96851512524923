import React, { useContext } from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  Flex,
  Stack,
  Button,
  Text,
  Image,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";

import { Input } from "../../forms";
import { AuthContext } from "../../../providers/AuthProvider";
import logoMiVivet from "../../../assets/logo_mi_vivet.png";

const formSchema = yup.object().shape({
  rut: yup.string().required("Requerido"),
  password: yup.string().min(5, "6 caracteres mínimos").required("Requerido"),
});

const SignInPage = () => {
  const { signIn } = useContext(AuthContext);
  const toast = useToast();

  const submit = async (values) => {
    const { rut, password } = values;
    try {
      await signIn(rut, password);
    } catch (error) {
      if (error.status === 429) {
        toast({
          title: "Demasiados intentos.",
          description: "Demasiados intentos. Intente mas tarde.",
          status: "error",
        });
      } else if (error.status === 401) {
        toast({
          title: "Datos erroneos",
          description: "RUT o contraseña no validas.",
          status: "error",
        });
      } else {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <Flex minH="100vh" align="center" justify="center">
        <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6} flex="1">
          <Stack align="center">
            {/* <Heading>Vivet</Heading> */}
            <Image src={logoMiVivet} alt="Logo Vivet" maxW={250} />
            {/* <Text
              fontSize="lg"
              align="center"
              color={useColorModeValue("gray.600", "gray.400")}
            >
              Ingresar a Vivet
            </Text> */}
          </Stack>

          <Formik
            initialValues={{ rut: "", password: "" }}
            validationSchema={formSchema}
            onSubmit={submit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={5}>
                  <Input name="rut" type="text" title="RUT" />
                  <Input name="password" type="password" title="Contraseña" />

                  <Stack spacing={5}>
                    <Button
                      variant="primary"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      Ingresar
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Flex>
    </>
  );
};

export default SignInPage;
