import { authClient } from "../network/AuthClient";

class AuthRepository {

  check() {
    return authClient.check();
  }

  signIn(rut, password) {
    return authClient.signIn(rut, password);
  }

  signOut() {
    return authClient.signOut();
  }
}

export const authRepository = new AuthRepository();