import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  HStack,
  VStack,
  Text,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';
import Uf from '../format/Uf';

const OportunidadFinanciamiento = ({ financiamiento, ...props }) => {

  return (
    <>
      <VStack {...props} bg={useColorModeValue("white", "gray.700")}
        _hover={{
          // eslint-disable-next-line react-hooks/rules-of-hooks
          bg: useColorModeValue("white", "gray.700"),
        }}
        rounded="lg"
        alignItems="stretch"
        shadow="base"
        py={2}
        px={3}>

        <HStack fontSize="sm" alignItems="flex-end">
          <Text fontWeight="bold">{financiamiento.nombre_tfi}</Text>
          <Spacer />
          <Uf>{financiamiento.monto_uf_fin}</Uf>
        </HStack>
        <HStack fontSize="sm" alignItems="flex-end">
          <Text>{financiamiento.nombre_efi}</Text>
          <Spacer />
          <Text>Cuotas: {financiamiento.cuotas_fin}</Text>
        </HStack>
      </VStack>

      
    </>
  );
};

OportunidadFinanciamiento.propsTypes = {
  financiamiento: PropTypes.object.isRequired,
}

export default memo(OportunidadFinanciamiento);