import React from 'react';
import {
  Text,
} from '@chakra-ui/react';
import { rut } from '../../core/helpers';

const Rut = ({ children, ...props}) => {
  return <>
    <Text {...props}>
      {rut(children)}
    </Text>
  </>;
};

export default Rut;