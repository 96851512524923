import { oportunidadesClient } from "../network/OportunidadesClient";

class OportunidadesRepository {

  all() {
    return oportunidadesClient.all();
  }

  info({queryKey}) {
    const [_key, { idOpp }] = queryKey;
    return oportunidadesClient.info(idOpp);
  }
  
}

export const oportunidadesRepository = new OportunidadesRepository();