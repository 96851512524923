import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import Uf from '../format/Uf';
import OportunidadFinanciamiento from './OportunidadFinanciamiento';
import IfEmpty from '../format/IfEmpty';
import { dateToHuman } from '../../core/helpers';

const FinanciamientoAhorro = ({ financiamiento, ahorro, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <OportunidadFinanciamiento financiamiento={financiamiento} onClick={onOpen} {...props} />

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{financiamiento.nombre_tfi}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table w="100%" variant="simple">
                <Tbody>
                  <Tr>
                    <Th px={0}>Monto</Th>
                    <Td px={0} isTruncated><Uf>{financiamiento.monto_uf_fin}</Uf></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Vencimiento</Th>
                    <Td px={0} isTruncated>{dateToHuman(ahorro.fecha_vencimiento_aho)}</Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Estado</Th>
                    <Td px={0} isTruncated><IfEmpty>{ahorro.nombre_eah}</IfEmpty></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Banco</Th>
                    <Td px={0}><IfEmpty isTruncated>{ahorro.nombre_ban}</IfEmpty></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Número de Libreta</Th>
                    <Td px={0}><IfEmpty isTruncated>{ahorro.numero_libreta_aho}</IfEmpty></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Titular Cuenta de Ahorro</Th>
                    <Td px={0}><IfEmpty isTruncated>{ahorro.nombre_ttc}</IfEmpty></Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

FinanciamientoAhorro.propsTypes = {
  financiamiento: PropTypes.object.isRequired,
  ahorro: PropTypes.object.isRequired,
}

export default memo(FinanciamientoAhorro);