import React, { useMemo, useRef } from 'react';
import PropsTypes from 'prop-types';
import {
  InputGroup,
  InputRightElement,
  Button,
  Input,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

const SearchInput = ({ onChange, value, placeholder, wait = 1000 }) => {
  const seachInput = useRef(null);

  const onFilter = useMemo(() => debounce(async (e) => {
    onChange(e.target.value);
  }, wait), [onChange, wait]);

  const clearFilter = () => {
    seachInput.current.value = "";
    onChange("");
  };

  return (
    <InputGroup size='md'>
        <Input
          pr="5.5rem"
          type="text"
          placeholder={placeholder}
          backgroundColor="white"
          onChange={onFilter}
          ref={seachInput}
          defaultValue={value}
        />
        <InputRightElement width="4.5rem" mr={2}>
          <Button h="1.75rem" size="sm" onClick={clearFilter}>Limpiar</Button>
        </InputRightElement>
      </InputGroup>
  );
};

SearchInput.propsTypes = {
  onChange: PropsTypes.func.isRequired,
  value: PropsTypes.string,
  placeholder: PropsTypes.string.isRequired,
  wait: PropsTypes.number,
};

export default SearchInput;