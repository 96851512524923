import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
} from '@chakra-ui/react';

const OportunidadCliente = ({ cliente }) => {
  return (
    <TableContainer>
      <Table w="100%" variant="simple">
        <Tbody>
          <Tr>
            <Th>RUT</Th>
            <Td px={0} isTruncated>{cliente.rut_format}</Td>
          </Tr>
          <Tr>
            <Th>Nombre</Th>
            <Td px={0} isTruncated>{cliente.nombre_completo}</Td>
          </Tr>
          <Tr>
            <Th>Email</Th>
            <Td px={0} isTruncated>{cliente.email_cli}</Td>
          </Tr>
          <Tr>
            <Th>Nacionalidad</Th>
            <Td px={0}><Text isTruncated>{cliente.nombre_nac}</Text></Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

OportunidadCliente.propsTypes = {
  cliente: PropTypes.object.isRequired,
}

export default memo(OportunidadCliente);