import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
} from '@chakra-ui/react';
import { AuthContext } from '../../providers/AuthProvider';

const EstadoOportunidad = ({ children, idEop, ...props }) => {
  const { constantes } = useContext(AuthContext);

  let color = 'gray.500';

  switch (idEop) {
    case constantes.EstadoOportunidad.COTIZACION:
      color = "green";
      break;
    case constantes.EstadoOportunidad.PRERESERVA:
      color = "blue";
      break;
    case constantes.EstadoOportunidad.RESERVA:
      color = "blue";
      break;
    case constantes.EstadoOportunidad.CIERRE:
      color = "teal";
      break;
    case constantes.EstadoOportunidad.PROMESA:
      color = "orange";
      break;
    case constantes.EstadoOportunidad.ESCRITURA:
      color = "red";
      break;
    case constantes.EstadoOportunidad.ANULADA:
      color = "gray";
      break;
    case constantes.EstadoOportunidad.CADUCADA:
      color = "gray";
      break;
    case constantes.EstadoOportunidad.DESISTIDA:
      color = "gray";
      break;

    default:
      break;
  }

  return <>
    <Badge variant="solid" colorScheme={color}>
      {children}
    </Badge>
  </>;
};

EstadoOportunidad.propsTypes = {
  idEop: PropTypes.number.isRequired,
}

export default memo(EstadoOportunidad);