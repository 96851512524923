import React from 'react';
import {
  Text,
} from '@chakra-ui/react';
import { uf } from '../../core/helpers';

const Uf = ({ children, ...props}) => {
  return <>
    <Text {...props}>
      {uf(children)} U.F.
    </Text>
  </>;
};

export default Uf;