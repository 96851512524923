import React from 'react';
import {
  Text,
} from '@chakra-ui/react';
import { isUndefined, isNull, isString } from 'lodash';

const IfEmpty = ({ children, ...props}) => {

  let value = children;
  if (isUndefined(value) || isNull(value) || (isString(value) && value.length === 0)) {
    value = '--';
  }

  return <>
    <Text {...props}>
      {value}
    </Text>
  </>;
};

export default IfEmpty;