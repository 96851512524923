import dayjs from "../../core/dayjs";

class TokenRepository {
  constructor() {
    this.token = null;
  }

  setTokenFromApiResponse(result) {
    const data = {
      token: result.access_token,
      expire: dayjs().add(result.expires_in, "second").unix(),
    }

    this.setToken(data);
  }
  
  setToken(token) {
    this.token = token;

    localStorage.setItem("token", token.token);
    localStorage.setItem("expire", token.expire.toString());
  }

  getToken() {
    if (this.token) {
      return this.token;
    }

    const token = localStorage.getItem("token");
    const expire = localStorage.getItem("expire");

    if (token && expire) {
      return { token, expire: parseInt(expire) };
    }

    return null;
  }

  removeToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("expire");
    this.token = null;
  }
}

export const tokenRepository = new TokenRepository();