import axios from "axios";
import jwt_decode from "jwt-decode";
import { isUndefined } from "lodash";
import { tokenRepository } from "../data/repository/TokenRepository";
import Config from "./Config";
import dayjs from "./dayjs";

const instance = axios.create({
  baseURL: Config.apiUrl,
  timeout: 20000,
});

instance.interceptors.request.use(async function (config) {
  if (isUndefined(config.headers)) {
    config.headers = {};
  }

  config.headers.Accept = "application/json";
  if (config.withoutToken === true) {
    return config;
  }

  let tokenData = tokenRepository.getToken();
  let needRefresh = true;
  if (tokenData != null) {
    const decode = jwt_decode(tokenData.token);
    needRefresh = dayjs.unix(decode.exp).diff(dayjs()) < 1;
  }

  if (needRefresh && tokenData != null) {
    try {
      const response = await axios.post(
        `${Config.apiUrl}/auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenData.token}`,
            Accept: "application/json",
          },
        }
      );

      tokenRepository.setTokenFromApiResponse(response.data);
      tokenData = tokenRepository.getToken();
    } catch (error) {
      tokenRepository.removeToken();
    }
  }

  if (tokenData != null) {
    config.headers.Authorization = `Bearer ${tokenData.token}`;
  }

  return config;
});

instance.interceptors.response.use(null, function (error) {
  if (error.response) {
    const message = error.response.data?.message;
    if (message) {
      error.message = message;
    }
  }

  return Promise.reject(error);
});

export { instance as axios };