import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  HStack,
  VStack,
  Text,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';

const OportunidadUnidades = ({ unidades, ...props }) => {
  return (
    <VStack {...props}>
      {unidades.map((unidad) => (
        // eslint-disable-next-line react-hooks/rules-of-hooks
        <VStack bg={useColorModeValue("white", "gray.700")}
        _hover={{
          // eslint-disable-next-line react-hooks/rules-of-hooks
          bg: useColorModeValue("white", "gray.700"),
        }}
        rounded="lg"
        alignItems="stretch"
        shadow="base"
        py={2}
        px={3}
        key={unidad.id_uni}>

        <HStack alignItems="flex-end">
          <Text fontWeight="bold">{unidad.lote_uni}</Text>
          <Spacer />
          <Text>{unidad.nombre_cla}</Text>
        </HStack>
        <HStack alignItems="flex-end">
          <Text isTruncated noOfLines={1}>{unidad.direccion_calle_uni}</Text>
        </HStack>
        <HStack alignItems="flex-end">
          <Text>{unidad.direccion_numero_uni}</Text>
          <Spacer />
          <Text>{unidad.tipologia_uni}</Text>
        </HStack>
      </VStack>
      ))}
    </VStack>
    
  );
};

OportunidadUnidades.propsTypes = {
  unidades: PropTypes.array.isRequired,
}

export default memo(OportunidadUnidades);