import React from 'react';
import { Center, Spinner, } from "@chakra-ui/react";

const Loading = ({ children, ...props }) => {
  return <>
    <Center {...props}>
      <Spinner size="lg" />
    </Center>
  </>;
};

export default Loading;