import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  VStack,
  StackDivider,
  Button,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { DataContext } from "../../providers/DataProvider";
import { oportunidadesRepository } from '../../data/repository/OportunidadesRepository';
import Oportunidad from './Oportunidad';
import { filterSearchArray } from "../../core/helpers";
import SearchInput from './SearchInput';
import Loading from '../components/Loading';

const Oportunidades = () => {
  console.log("Oportunidades");

  const { queryOpp, setQueryOpp } = useContext(DataContext);

  const perPage = 20;
  const [rows, setRows] = useState(perPage);
  const [dataFilter, setDataFilter] = useState([]);

  const onLoadMore = () => {
    setRows(rows + perPage);
  };

  const filterData = () => {
    if (!data) {
      setDataFilter([]);
    }
    else if (queryOpp.trim().length > 0) {
      const filters = queryOpp.split("&");
      console.log("filters", filters, queryOpp);

      setDataFilter(data.filter((it) => {
        return filterSearchArray(filters, [
          it.id_opp,
          it.lote_uni,
          it.rut_cli,
          it.nombre_cli,
          it.nombre_eop,
          it.nombre_tci,
          it.nombre_usr,
          it.nombre_mod,
          it.nombre_ser,
          it.nombre_fre,
          it.nombre_cla,
          it.fecha_estado_format,
          it.tipologia_reporte_uni
        ]);
      }));
    }
    else {
      setDataFilter(data);
    }
  };

  const { isLoading, isError, data, error } = useQuery("oportunidades", oportunidadesRepository.all);

  useEffect(() => {
    filterData();
  }, [queryOpp, data]);

  if (isLoading) {
    return <Loading my={3} />
  }

  if (isError) {
    return <Box>Error: {error.message}</Box>;
  }

  return (
    <VStack w="100%" align="stretch" spacing={3} divider={<StackDivider borderColor='gray.200' />}>
      <SearchInput onChange={setQueryOpp} value={queryOpp} placeholder="Buscar oportunidad..." />
      
      {dataFilter.slice(0, rows).map((it) => {
        return <Oportunidad key={it.id_opp} oportunidad={it} />;
      })}

      <Button colorScheme="blue" onClick={onLoadMore} isDisabled={dataFilter.length < rows}>Mostrar mas oportunidades</Button>
    </VStack>
  );
};

export default Oportunidades;