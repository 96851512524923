import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  HStack,
  VStack,
  Text,
  Spacer,
  LinkBox,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as LinkRouter } from "react-router-dom";

import Rut from "../format/Rut";
import Uf from "../format/Uf";
import EstadoOportunidad from '../format/EstadoOportunidad';
import TipoCliente from '../format/TipoCliente';

const Oportunidad = ({ oportunidad }) => {
  return (
    <LinkBox as={LinkRouter} to={"/oportunidad/" + oportunidad.id_opp} >
      <VStack bg={useColorModeValue("white", "gray.700")}
        _hover={{
          bg: useColorModeValue("white", "gray.700"),
        }}
        rounded="lg"
        alignItems="stretch"
        shadow="base"
        py={2}
        px={3}>

        {/* <Icon as={FaFileContract} w={6} h={6} /> */}

        <HStack fontSize="sm" alignItems="flex-end">
          <Text fontWeight="bold">{oportunidad.id_opp}</Text>
          <Rut>{oportunidad.rut_cli}</Rut>
          <Spacer />
          <Text isTruncated noOfLines={1} maxW="40%">{oportunidad.nombre_cli}</Text>
        </HStack>

        <HStack fontSize="sm" alignItems="flex-end">
          <Text>{oportunidad.lote_uni}</Text>
          <Text>{oportunidad.nombre_cla}</Text>
          <Spacer />
          {/* <Text>{oportunidad.nombre_mod}</Text> */}
          <Text>{oportunidad.tipologia_reporte_uni}</Text>
        </HStack>

        <HStack fontSize="sm" alignItems="flex-end">
          <Uf>{oportunidad.precio_final_opp}</Uf>
          <Spacer />
          <TipoCliente idTci={oportunidad.id_tci}>{oportunidad.nombre_tci}</TipoCliente>
          <EstadoOportunidad idEop={oportunidad.id_eop}>{oportunidad.nombre_eop}</EstadoOportunidad>
        </HStack>
      </VStack>
    </LinkBox>
  );
};

Oportunidad.propsTypes = {
  oportunidad: PropTypes.object.isRequired,
}

export default memo(Oportunidad);