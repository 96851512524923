import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
} from '@chakra-ui/react';
import { AuthContext } from '../../providers/AuthProvider';

const TipoCliente = ({ children, idTci, ...props }) => {
  const { constantes } = useContext(AuthContext);

  let color = 'gray';

  switch (idTci) {
    case constantes.TipoCliente.VULNERABLE:
      color = "orange";
      break;
    case constantes.EstadoOportunidad.MEDIO:
      color = "green";
      break;
    case constantes.EstadoOportunidad.PRIVADO:
      color = "blue";
      break;
    default:
      break;
  }

  return <>
    <Badge {...props} variant="solid" colorScheme={color}>
      {children}
    </Badge>
  </>;
};

TipoCliente.propsTypes = {
  idTci: PropTypes.number.isRequired,
}

export default memo(TipoCliente);