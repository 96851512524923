import { axios } from "../../core/axios";

class OportunidadesClient {
  all(idCli) {
    return new Promise((resolve, reject) => {
      axios
        .get("/oportunidades")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  info(idOpp) {
    return new Promise((resolve, reject) => {
      axios
        .get("/oportunidades/" + idOpp)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const oportunidadesClient = new OportunidadesClient();