import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  VStack,
} from '@chakra-ui/react';
import { AuthContext } from "../../providers/AuthProvider";
import FinanciamientoSubsidio from './FinanciamientoSubsidio';
import FinanciamientoChip from './FinanciamientoChip';
import FinanciamientoAhorro from './FinanciamientoAhorro';
import FinanciamientoCuotas from './FinanciamientoCuotas';

const OportunidadFinanciamientos = ({
  financiamientos,
  subsidio,
  chip,
  ahorro,
  cuotas,
  ...props
}) => {
  const { constantes } = useContext(AuthContext);

  return (
    <VStack {...props} alignItems="stretch">
      {financiamientos.map((f) => {
        switch (f.id_tfi) {
          case constantes.TipoFinanciamiento.SUBSIDIO:
            return <FinanciamientoSubsidio key={f.id_fin} financiamiento={f} subsidio={subsidio} />;
          case constantes.TipoFinanciamiento.CREDITO_HIPOTECARIO:
            return <FinanciamientoChip key={f.id_fin} financiamiento={f} chip={chip} />;
          case constantes.TipoFinanciamiento.AHORRO:
            return <FinanciamientoAhorro key={f.id_fin} financiamiento={f} ahorro={ahorro} />;
          default:
            const _cuotas = cuotas.filter((c) => c.id_fin === f.id_fin);
            return <FinanciamientoCuotas key={f.id_fin} financiamiento={f} cuotas={_cuotas} />;
        }
      })}
    </VStack>
  );
};

OportunidadFinanciamientos.propsTypes = {
  financiamientos: PropTypes.array.isRequired,
  subsidio: PropTypes.object,
  chip: PropTypes.object,
  ahorro: PropTypes.object,
  cuotas: PropTypes.object,
}

export default memo(OportunidadFinanciamientos);