import { VStack } from '@chakra-ui/react'
import React from 'react';
import Oportunidades from '../../vivet/Oportunidades';

const DashboardPage = () => {

  return (
    <VStack align="stretch">
      <Oportunidades mt={1} />
    </VStack>
  );
}

export default DashboardPage;
