import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  VStack,
  DrawerContent,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { FiHome, FiMenu, FiLogOut } from "react-icons/fi";

import { AuthContext } from "../../providers/AuthProvider";
import { isNull, isUndefined } from "lodash";

import logoMiVivet from "../../assets/logo_mi_vivet.png";
import logoMiVivetSmall from "../../assets/logo_mi_vivet_small.png";

const SideBar = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.50", "gray.800")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} px={4} pb={4} pt={{ base: "24", md: "4" }}>
        {children}
      </Box>
    </Box>
  );
};

SideBar.propTypes = {
  children: PropTypes.node.isRequired,
};

const SidebarContent = ({ onClose, ...rest }) => {
  const { auth, signOut } = useContext(AuthContext);
  // const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const handleSignOut = useCallback(async () => {
    await signOut();
    navigate("/");
  }, [navigate, signOut]);

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <VStack alignItems="start" spacing={1}>
          {/* <Text fontSize="2xl" fontWeight="bold">
            Vivet
          </Text> */}
          <Image src={logoMiVivet} alt="Mi Vivet" h="50px" />
          <Text fontSize="xs">{auth.user.email_usr}</Text>
        </VStack>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <NavItem to="/" onClose={onClose} key="Mis Negocios" icon={FiHome}>
        Mis Negocios
      </NavItem>
      {/* <NavItem to="/profile" key="Profile" icon={FiUser}>
        Profile
      </NavItem> */}
      <NavItem onClick={handleSignOut} onClose={onClose} key="Salir" icon={FiLogOut}>
        Salir
      </NavItem>
      {/* <FormControl display='flex' alignItems='center' p="4" mx="4">
        <FormLabel htmlFor='email-alerts' mb='0'>
          Dark Mode
        </FormLabel>
        <Switch colorScheme="dark" isChecked={colorMode === 'dark'} onChange={toggleColorMode} />
      </FormControl> */}
    </Box>
  );
};

SidebarContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

// const NavItem = ({ to, onClick, icon, children, ...rest }) => {
const NavItem = ({ to, onClick, onClose, icon, children, ...rest }) => {
  let params = {};

  if (!isUndefined(to) && !isNull(to)) {
    params.to = to;
    params.as = LinkRouter;
    params.onClick = () => onClose();
  }

  if (!isUndefined(onClick) && !isNull(onClick)) {
    params.onClick = () => {
      onClick();
      onClose();
    }
  }

  return (
    <>
      <Link {...params} style={{ textDecoration: "none" }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            // eslint-disable-next-line react-hooks/rules-of-hooks
            bg: useColorModeValue("blue.400", "blue.600"),
            color: "white",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    </>
  );
};

NavItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  icon: PropTypes.elementType,
  children: PropTypes.node.isRequired,
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      position="fixed"
      top="0"
      left="0"
      w="100%"
      h={20}
      zIndex="1003"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      {/* <Text fontSize="2xl" ml="8" fontWeight="bold">
        Vivet
      </Text> */}
      <Image src={logoMiVivetSmall} alt="Mi Vivet" h="40px" ml={2} />
    </Flex>
  );
};

MobileNav.propTypes = {
  onOpen: PropTypes.func.isRequired,
};

export default SideBar;
