import { axios } from "../../core/axios";

class AuthClient {
  check() {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/me", {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  signIn(rut, password) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", { rut, password }, { withoutToken: true })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/logout", {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export const authClient = new AuthClient();