import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { SideBar } from './ui/components';
import SignInPage from './ui/views/auth/SignInPage';
import DashboardPage from './ui/views/comercial/DashboardPage';
import NotFoundPage from './ui/views/common/NotFoundPage';
import LoadingPage from './ui/views/common/LoadingPage';
import { AuthContext } from "./providers/AuthProvider";
import OportunidadPage from "./ui/views/comercial/OportunidadPage";

const App = () => {
  const { auth } = useContext(AuthContext);

  if (!auth.loaded) {
    return <LoadingPage />;
  }

  if (!auth.logged) {
    return (
      <>
        <Routes>
          <Route path="/" element={<SignInPage />}></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </>
    );
  }

  return (
    <>
      <SideBar>
        <Routes>
          <Route path="/404" element={<NotFoundPage />}></Route>
          <Route path="/oportunidad/:id" element={<OportunidadPage />}></Route>
          <Route path="/" element={<DashboardPage />}></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </SideBar>
    </>
  );

}

export default App;
