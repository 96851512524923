import React, { useState, useEffect } from "react";
import { authRepository } from "../data/repository/AuthRepository";
import { tokenRepository } from "../data/repository/TokenRepository";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    logged: false,
    loaded: false,
  });

  const [constantes, setConstantes] = useState({});

  const setAuthSignIn = (user) => {
    setAuth({
      user,
      logged: true,
      loaded: true,
    });
  };

  const setAuthSignOut = () => {
    setAuth({
      user: null,
      logged: false,
      loaded: true,
    });
  };

  const check = () => {
    return new Promise((resolve, reject) => {
      authRepository
        .check()
        .then((res) => {
          setConstantes(res.constantes);
          setAuthSignIn(res.usuario);
          resolve(res);
        })
        .catch((err) => {
          setAuthSignOut();
          reject(err);
        });
    });
  };

  const signOut = () => {
    return new Promise((resolve, reject) => {
      authRepository
        .signOut()
        .then((res) => {
          setAuthSignOut();
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      authRepository
        .signIn(email, password)
        .then((res) => {
          tokenRepository.setTokenFromApiResponse(res);
          
          check().then(() => {
            resolve(res);
          }).catch(() => {
            setAuthSignOut();
            reject(res);
          });
        })
        .catch((err) => {
          setAuthSignOut();
          reject(err);
        });
    });
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        signIn,
        signOut,
        check,
        constantes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
