import React from 'react';
import { useQuery } from 'react-query';
import {
  Text,
  Heading,
  HStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
} from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import { oportunidadesRepository } from '../../../data/repository/OportunidadesRepository';
import Loading from '../../components/Loading';
import OportunidadCliente from '../../vivet/OportunidadCliente';
import OportunidadUnidades from '../../vivet/OportunidadUnidades';
import OportunidadFinanciamientos from '../../vivet/OportunidadFinanciamientos';

const OportunidadPage = () => {
  const params = useParams();

  const { isLoading, isError, data, error } = useQuery(
    ['oportunidades_info', { idOpp: params.id }],
    oportunidadesRepository.info
  );

  if (isLoading) {
    return <Loading my={3} />
  }

  if (isError) {
    return <Text>Error: {error.message}</Text>
  }

  const {
    oportunidad,
    cliente,
    unidades,
    complementos,
    financiamientos,
    subsidio,
    chip,
    ahorro,
    cuotas,
  } = data;

  return (
    <>
      <HStack mb={3}>
        <Heading as="h3" size="lg">
          Negocio - {oportunidad.id_opp}
        </Heading>
      </HStack>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Mis Datos
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px={0} pb={4}>
            <OportunidadCliente cliente={cliente} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Unidades
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px={0} pb={4}>
            <OportunidadUnidades unidades={unidades} align="stretch" />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Finaciamientos
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel px={0} pb={4}>
            <OportunidadFinanciamientos 
              financiamientos={financiamientos}
              subsidio={subsidio}
              ahorro={ahorro}
              chip={chip}
              cuotas={cuotas}
              align="stretch" 
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight="bold">
                Documentos
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

export default OportunidadPage;
