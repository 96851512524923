import _ from "lodash";
import numeral from "numeral";
import dayjs from "./dayjs";

// load a locale
numeral.register('locale', 'cl', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  // ordinal : function (number) {
  //     return number === 1 ? 'er' : 'ème';
  // },
  currency: {
    symbol: '$'
  }
});

// switch between locales
numeral.locale('cl');

const optsNumberFormat = {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  fixed: 0,
  round: true,
  precision: 2
};

export const numberFormat = (number, opts) => {
  const options = _.extend(optsNumberFormat, opts);

  if (_.isString(number)) {
    number = parseFloat(number);
  }

  if (number % 1 !== 0 && options.round === true) {
    number = _.round(number, options.precision)
  }

  let format = "0,0";
  if (options.precision > 0) {
    format += "[.]" + "0".repeat(options.precision);
  }

  return number == null ? "" : numeral(number).format(format);
};

export const rut = (value) => {
  return numberFormat(value.slice(0, -1)) + '-' + value.slice(-1);
};

export const uf = (value) => {
  if (value == null || value === 0 || value === "") {
    return 0;
  }

  // if (!_.isInteger(decimales)) {
  //     decimales = window.vivet.Config.DECIMALES_UF;
  // }


  return numberFormat(value, { precision: 2, round: false });
}

export const dateToHuman = (date) => {
  if (date == null) {
    return "--";
  }

  return dayjs(date).format("DD-MM-YYYY");
}

export const datetimeToHuman = (date) => {
  if (date == null) {
    return "--";
  }

  return dayjs(date).format("DD-MM-YYYY HH:mm");
}

export const pesos = (value) => {
  if (value == null || value === 0 || value === "") {
    return 0;
  }

  // if (!_.isInteger(decimales)) {
  //     decimales = window.vivet.Config.DECIMALES_UF;
  // }


  return numberFormat(value, { precision: 0, round: false });
}

export const filterSearchArray = (query, values) => {
  if (!_.isArray(query) || query.length == 0) {
    return true;
  }

  let ok = true;
  // let query = _.map(query, q => q.toUpperCase());
  let valores = values.join("").toUpperCase();
  _.each(query, function (q) {
    if (valores.indexOf(q.toUpperCase()) === -1) {
      ok = false;
      return false;
    }
  });

  return ok;
};