import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import Uf from '../format/Uf';
import OportunidadFinanciamiento from './OportunidadFinanciamiento';
import IfEmpty from '../format/IfEmpty';
import BoolWord from '../format/BoolWord';

const FinanciamientoChip = ({ financiamiento, chip, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <OportunidadFinanciamiento financiamiento={financiamiento} onClick={onOpen} {...props} />

      <Modal isOpen={isOpen} onClose={onClose}  size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{financiamiento.nombre_tfi}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <TableContainer>
              <Table w="100%" variant="simple">
                <Tbody>
                  <Tr>
                    <Th px={0}>Monto</Th>
                    <Td px={0} isTruncated><Uf>{financiamiento.monto_uf_fin}</Uf></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Banco / Mutuaria</Th>
                    <Td px={0} isTruncated>{chip.nombre_ban}</Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Plazo (años)</Th>
                    <Td px={0} isTruncated><IfEmpty>{chip.nombre_eah}</IfEmpty></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Tasa (Anual)</Th>
                    <Td px={0}><IfEmpty isTruncated>{chip.ch_tasa_fin}</IfEmpty></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Mutuo</Th>
                    <Td px={0}><BoolWord>{chip.ch_mutuo_fin}</BoolWord></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Letras</Th>
                    <Td px={0}><BoolWord>{chip.ch_letras_fin}</BoolWord></Td>
                  </Tr>
                  <Tr>
                    <Th px={0}>Garantía 100%</Th>
                    <Td px={0}><BoolWord>{chip.ch_garantia_100_fin}</BoolWord></Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

FinanciamientoChip.propsTypes = {
  financiamiento: PropTypes.object.isRequired,
  chip: PropTypes.object.isRequired,
}

export default memo(FinanciamientoChip);