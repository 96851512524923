import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
} from '@chakra-ui/react';
import { AuthContext } from '../../providers/AuthProvider';

const EstadoCuota = ({ children, idEcu, ...props }) => {
  const { constantes } = useContext(AuthContext);

  let color = 'gray.500';

  switch (idEcu) {
    case constantes.EstadoCuota.PENDIENTE:
      color = "gray";
      break;
    case constantes.EstadoCuota.ABONADO:
      color = "orange";
      break;
    case constantes.EstadoCuota.ATRASADO:
      color = "red";
      break;
    case constantes.EstadoCuota.PAGADO:
      color = "green";
      break;
    default:
      break;
  }

  return <>
    <Badge {...props} variant="solid" colorScheme={color}>
      {children}
    </Badge>
  </>;
};

EstadoCuota.propsTypes = {
  idEcu: PropTypes.number.isRequired,
}

export default memo(EstadoCuota);